export const GetIconMixin = {
  methods: {
    getPaymentServiceIcon(name) {
      switch (name) {
        case 'line':
        case 'linepay':
          return require('@/assets/icons/linepay.png');
        case 'jko':
          return require('@/assets/icons/jko-pay.svg');
        case 'icashpay':
          return require('@/assets/icons/icash-pay.svg');
        case 'easywallet':
          return require('@/assets/icons/easywallet.png');
        case 'tappay':
          return require('@/assets/icons/tappay.png');
        case 'apple':
          return require('@/assets/icons/apple-pay.svg');
        case 'google':
          return require('@/assets/icons/google-pay.svg');
        case 'direct':
        case 'credit':
          return require('@/assets/icons/credit-cards.png');
        default:
          return null;
      }
    }
  }
};