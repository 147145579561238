<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row>
      <!-- QUERY SETTINGS -->
      <v-col cols="12">
        <v-row>
          <v-col cols="12" sm="4" lg="3">
            <DateRangePicker @update="onDateRangeUpdated"
              :def-dates="dates">
            </DateRangePicker>
          </v-col>
          <v-col cols="12" sm="4" lg="3">
            <ScopeSelector vertical
              @org-selected="onOrganizationSelected"
              @mch-selected="onMerchantSelected"
              :preselected-merchant-id="$route.query.merchantId">
            </ScopeSelector>
          </v-col>
          <v-col cols="12" sm="4" lg="3">
            <v-btn block large color="purple darken-3" @click="exportDonations">
              <v-icon left>mdi-file-export</v-icon>
              Download as Excel
            </v-btn>
            <v-btn block large text color="grey darken-3" @click="forceRefresh">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <!-- LIST OF DONATIONS -->
      <v-col cols="12" xl="8">
        <v-card class="mt-0">
          <v-card-text>
            <SSRTable ref="list-donations"
              :data-url="loadUrl" :data-key="'donations'" :schema="schema">

              <div slot="merchant_name" slot-scope="{item}">
                <span>{{ item.merchant_name }}</span><br>
                <span class="text-body-2" v-if="item.counter_id"><strong>Counter</strong>&nbsp;{{ item.counter_name }}</span>
              </div>

              <div slot="donation" slot-scope="{item}">
                Donated ${{ Number(item.donation).toLocaleString() }}
                <br>
                Paid total ${{ Number(item.amount).toLocaleString() }}
              </div>

              <div slot="donor" slot-scope="{item}">
                {{ item.donor_name }}
                <br>
                {{ item.donor_phone }}
              </div>

              <div slot="charity" slot-scope="{item}">
                {{ item.charity_context? item.charity_context.donate_to: '' }}
              </div>

              <div slot="status" slot-scope="{item}">
                <div>
                  <v-chip small outlined color="green darken-1" v-if="item.status === 'completed'">
                    {{ $t('views.vasDonations.paid') }}<v-icon right>mdi-check</v-icon>
                  </v-chip>
                  <v-chip small outlined color="amber darken-3" v-else-if="item.status === 'refund'">
                    {{ $t('views.vasDonations.refunded') }}<v-icon right>mdi-cancel</v-icon>
                  </v-chip>
                  <v-chip small outlined color="grey accent-3" v-else>
                    {{ $t('views.vasDonations.incomplete') }}<v-icon right>mdi-progress-clock</v-icon>
                  </v-chip>
                </div>
              </div>


              <div slot="payment_order_id" slot-scope="{item}">
                <span class="clickable" v-if="item.payment_order_id"
                  @click="showOrderInfoDialog(item)">
                  {{ item.payment_order_id }}
                </span><br>
                <span v-if="item.payment_order_id">{{ item.paid_at }}</span>
              </div>
            </SSRTable>

          </v-card-text>
        </v-card>
      </v-col>

      <!-- LIST OF CHARITIES -->
      <v-col cols="12" xl="4">
        <v-card class="mt-0">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-btn rounded elevation="0" color="warning" @click="showCreateCharityDialog">
                  <v-icon left>mdi-heart-plus</v-icon>Add a Charity Organization
                </v-btn>
              </v-col>
              <v-col cols="12">
                <SSRTable ref="list-charities"
                  :data-url="loadCharitiesUrl" :schema="schemaCharities">

                  <div slot="donate_to" slot-scope="{item}">
                    {{ item.donate_to }}<br>
                    <span class="grey--text">{{ item.organization_name }}</span>
                  </div>

                  <div slot="actions" slot-scope="{item}">
                    <v-btn fab x-small elevation="0" color="info" @click="showCharityInfoDialog(item)">
                      <v-icon>mdi-information-variant</v-icon>
                    </v-btn>
                  </div>
                </SSRTable>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>

    <!-- Dialog - CREATION OF CHARITY ORG -->
    <v-dialog v-model="dialog.charity.show" width="60%">
      <v-card>
        <v-card-title>
          Create a Charity Organization
        </v-card-title>
        <v-card-text>
          <v-form ref="ps-form">
            <v-row>
              <v-col cols="6">
                <v-text-field outlined dense label="Name (Donate To)" v-model="dialog.charity.model.donateTo">
                </v-text-field>
              </v-col>
              <v-col cols="8">
                <v-textarea outlined label="Intro" v-model="dialog.charity.model.intro">
                </v-textarea>
              </v-col>
              <v-col cols="4">
                <v-textarea outlined label="Remark" v-model="dialog.charity.model.remark">
                </v-textarea>
              </v-col>
              <v-col cols="8">
                <v-text-field outlined dense label="Image URL" v-model="dialog.charity.model.imageUrl">
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-img contain v-if="dialog.charity.model.imageUrl" :src="dialog.charity.model.imageUrl">
                </v-img>
              </v-col>
              <v-col cols="6">
                <v-textarea outlined label="SMS Template" v-model="dialog.charity.model.smsTemplate"
                  placeholder="(Leave blank if SMS support is not required)">
                </v-textarea>
              </v-col>
              <v-col cols="6">
                <v-select chips multiple outlined label="Allowed Payment Services"
                  v-model="dialog.charity.model.allowedPaymentServices"
                  :items="dialog.charity.paymentServiceOptions">
                </v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions class="d-flex justify-end">
          <v-btn elevation="0" color="warning" @click="createCharity">
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog - Charity Info -->
    <v-dialog v-model="dialog.charityInfo.show" width="50%">
      <v-card>
        <v-img v-if="dialog.charityInfo.settings.images && dialog.charityInfo.settings.images.length"
          :src="dialog.charityInfo.settings.images[0]" height="320px">
        </v-img>
        <v-card-title>
          <span class="h6">Charity | </span>{{ dialog.charityInfo.charity.donate_to }}
        </v-card-title>
        <v-card-subtitle>
          {{ dialog.charityInfo.charity.organization_name }}
        </v-card-subtitle>
        <v-card-text>
          <p v-html="dialog.charityInfo.charity.donate_intro"></p>
          <p class="grey--text" v-html="dialog.charityInfo.charity.remark"></p>
          <div v-if="dialog.charityInfo.settings.sms && dialog.charityInfo.settings.sms.enabled">
            <v-divider class="my-2"></v-divider>
            <span class="text-h3">SMS Template</span><br>
            <span>{{ dialog.charityInfo.settings.sms.template }}</span>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog for showing detail and transactional history of the selected order -->
    <OrderDetailDialog :show.sync="dialog.orderInfo.show"
      :payment-order-id="dialog.orderInfo.paymentOrderId">
    </OrderDetailDialog>

  </v-container>
</template>

<script>
import SSRTable from '@/components/SSRTable';
import DateRangePicker from '@/components/DateRangePicker';
import ScopeSelector from '@/components/ScopeSelector';

import OrderDetailDialog from '@/views/dashboard/subviews/OrderDetailDialog';

import axios from 'axios';
import moment from 'moment';

export default {
  components: {
    SSRTable,
    DateRangePicker,
    ScopeSelector,
    OrderDetailDialog
  },

  mounted() {
    //NOTE: If a merchant ID is given in URL query, update scope filter immediately.
    if (this.$route.query.merchantId) {
      this.scope.merchant = { 'merchant_id': this.$route.query.merchantId };
    }
  },

  methods: {
    onDateRangeUpdated(dates) {
      this.dates = dates;
    },
    onOrganizationSelected(org) {
      this.scope.organization = org;
    },
    onMerchantSelected(merchant) {
      this.scope.merchant = merchant;
    },
    forceRefresh() {
      this.$refs['list-donations'].loadData();
    },

    createCharity() {
      if (this.user['level'] === 'superadmin' && !this.scope.organization) {
        alert(`Since you're a superadmin, you must select an organzation first.`);
        return;
      }
      
      this.dialog.charity.isProcessing = true;
      let model = this.dialog.charity.model;
      if (this.scope.organization)
        model['orgId'] = this.scope.organization['id'];
      if (model['imageUrl'])
        model['images'] = [ model['imageUrl'] ];
      console.log('createCharity', model);
      // return;
      axios.post(`${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/vas/donations/charities`, model)
      .then(response => {
        this.$refs['list-charities'].loadData();
        this.$notify({
          text: `Payment Service created: ${model.name}.`,
          type: 'success'
        });
      })
      .catch(err => {
        console.error(`Failed to create a new payment service`, err);
        this.$notify({
          text: `Failed to create Payment Service: ${model.name}.`,
          type: 'error'
        });
      })
      .finally(() => {
        this.dialog.charity.isProcessing = false;
        this.dialog.charity.show = false;
      });
    },

    showCreateCharityDialog() {
      this.dialog.charity.mode = 'create';
      this.dialog.charity.show = true;
    },
    // showUpdateCharityDialog() {
    //   this.dialog.charity.mode = 'create';
    //   this.dialog.charity.show = true;
    // },
    showCharityInfoDialog(charity) {
      this.dialog.charityInfo.charity = charity;
      this.dialog.charityInfo.settings = charity['settings'];
      console.log(this.dialog.charityInfo.settings);
      this.dialog.charityInfo.show = true;
    },
    showOrderInfoDialog(donation) {
      this.dialog.orderInfo.paymentOrderId = donation['payment_order_id'];
      this.dialog.orderInfo.show = true;
    },

    exportDonations() {
      let from = moment(this.dates[0]).startOf('day');
      let to = moment(this.dates[1]).endOf('day');
      window.open(`${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/vas/donations/export`
        + `?from=${from.unix()}&to=${to.unix()}`)
        + (this.scope.organization? `&orgId=${this.scope.organization['id']}`: '')
        + (this.scope.merchant? `&merchantId=${this.scope.merchant['merchant_id']}`: '');
    }
  },

  data() {
    return {
      dates: [
        moment().startOf('day').format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      ],
      scope: {
        organization: null,
        merchant: null
      },

      dialog: {
        charity: {
          show: false,
          isProcessing: false,
          mode: 'create',
          model: {
            donateTo: '',
            intro: '',
            remark: '',
            smsTemplate: '',
            allowedPaymentServices: [ 'tappay', 'linepay', 'easywallet', 'olink', 'taiwanpay', 'jko' ]
          },
          paymentServiceOptions: [ 'tappay', 'linepay', 'easywallet', 'olink', 'taiwanpay', 'jko' ]
        },

        charityInfo: {
          show: false,
          charity: { },
          settings: { }
        },

        orderInfo: {
          show: false,
          paymentOrderId: ''
        }
      }
    };
  },

  computed: {
    loadUrl() {
      let from = moment(this.dates[0]).startOf('day');
      let to = moment(this.dates[1]).endOf('day');
      return `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/vas/donations?timeFormat=${encodeURIComponent('YYYY/M/D h:mm:ssA')}`
        + `&from=${from.unix()}&to=${to.unix()}`
        + (this.scope.organization? `&orgId=${this.scope.organization['id']}`: '')
        + (this.scope.merchant? `&merchantId=${this.scope.merchant['merchant_id']}`: '');
    },
    schema() {
      return [
        { value: 'merchant_name', text: this.$t('views.vasDonations.merchantName') },
        { value: 'charity', text: this.$t('views.vasDonations.charity') },
        { value: 'donation', text: this.$t('views.vasDonations.donation'), align: 'end', width: 144, sortable: false },
        { value: 'donor', text: this.$t('views.vasDonations.donor'), align: 'end', width: 144, sortable: false },
        { value: 'status', text: this.$t('views.vasDonations.status') },
        { value: 'donated_at', text: this.$t('views.vasDonations.donatedAt') },
        { value: 'payment_order_id', text: this.$t('views.vasDonations.paymentOrderId') },
        // { value: 'actions', text: '' }
      ];
    },

    loadCharitiesUrl() {
      return `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/vas/donations/charities?timeFormat=${encodeURIComponent('YYYY/M/D h:mm:ssA')}`
        + (this.scope.organization? `&orgId=${this.scope.organization['id']}`: '')
    },
    schemaCharities() {
      return [
        { value: 'donate_to', text: 'Donate To' },
        // { value: 'is_enabled', text: 'Enabled?', align: 'center' },
        { value: 'actions', text: 'Info' }
      ];
    },

    user() {
      return this.$store.getters.user;
    }
  }
}
</script>

<style scoped>
span.clickable {
  cursor: pointer;
  color: darkcyan;
}
</style>